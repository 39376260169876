<template>
  <div>
    <div class="bg-white max-w-xxl grid grid-cols-1 md:grid-cols-12 border">
      <div class="form-enterprise sm:col-span-12 md:col-span-12 mx-auto">
        <h3 class="text-3xl font-extrabold">
          {{ stagelevel === 2 ? 'Submit the new' : 'Create new' }} <span class="secondary--text"> Post</span>
        </h3>
        <p class="mt-4 text-gray-200">
          Nice to create new post! wite your post..
        </p>

        <v-form class="form max-w-xxl" v-if="stagelevel === 1">
          <div class="grid grid-cols-1 md:grid-cols-12">
            <div class="sm:col-span-12 md:col-span-6 md:mr-2 text-white">
              <div class="form-item-large">
                <label class="">
                  Post title
                </label>
                <v-text-field v-model="name" type="text" class="form-input" placeholder="Your Name"
                  :error-messages="nameErrors.value" clearable :hide-details="nameErrors.isvalid" hint="ddd"
                  @input="$v.name.$touch()" @blur="$v.name.$touch()"></v-text-field>
              </div>

              <div class="form-item-large">
                <label class="">
                  Post type
                </label>
                <select v-model="post_type" class="form-input" placeholder="">
                  <option value="" disabled selected hidden>Post type</option>
                  <option v-for="post_t in post_list_type" :value="post_t">{{ post_t }}</option>
                </select>
              </div>

              <div class="form-item-large">
                <label class="">
                  Logo
                </label>
                <div class="relative border-2 border-gray-300 border-dashed rounded-lg p-4" id="dropzone"
                  @click="$refs.ref_logo_img.click()">
                  <input ref="ref_logo_img" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" :hidden="true"
                    @change="onImageChange" />
                  <div rounded="0" @click="$refs.ref_blog_img.click()" v-if="blog_image">

                    <v-img :src="blog_image ? blog_image : ''" alt="" class="w-auto h-100" cover>
                    </v-img>
                  </div>
                  <div class="text-center" v-else>
                    <v-icon size="60" color="secondary"> {{ icons.mdiCamera }}</v-icon>
                    <h3 class="mt-2 text-sm font-medium text-gray-900">
                      <label for="file-upload" class="relative cursor-pointer">
                        <span>Drag and drop</span>
                        <span class="text-indigo-600"> or browse </span>
                        <span>the logo</span>
                      </label>
                    </h3>
                    <p class="mt-1 text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-item-large">
                <label class="">
                  Write your post
                </label>
                <vue-editor v-model="about" outlined dense class="form-input text-left mb-3" label="Body"
                  placeholder="Write your post">
                </vue-editor>
              </div>
            </div>
          </div>
        </v-form>

        <!-- Validation form -->

        <v-form class="form w-full" v-if="stagelevel === 2">
          <div class="grid grid-cols-1 md:grid-cols-12">
            <div class="sm:col-span-12 md:col-span-6 md:mr-2 text-white">
            <div class="form-item-large">
              <label class="">
                Logo
              </label>
              <div class="relative border-2 border-gray-300 border-dashed rounded-lg p-4">
                <v-avatar size="100" rounded="0" @click="$refs.ref_blog_img.click()" v-if="blog_image">
                  <v-img :src="blog_image ? blog_image : ''" alt="" size="100" class="w-100" cover>
                  </v-img>
                </v-avatar>
              </div>
            </div>

            <div class="form-item-large">s
              <label class="">
                Post title
              </label>
              <div type="text" class="form-input">{{ name }}</div>
            </div>

            <div class="form-item-large">
              <label class="">
                Post type
              </label>
              <div class="form-input" placeholder="" disabled>
                {{post_type}}
              </div>
            </div>

            <div class="form-item-large">
              <label class="">
                Enterprise description
              </label>
              <div v-html="about" class="form-input" placeholder="Your Name" disabled ></div>
              
            </div>
          </div>
          </div>
        </v-form>

        <div class="action mt-8">
          <v-btn text color="error" @click="stagelevel === 2 ? goToPreviousStep() : backToList()">
            {{ stagelevel === 2 ? 'Back to form' : 'Cansel' }}
          </v-btn>
          <v-btn color="primary" @click="stagelevel === 2 ? submitCreateNewCompany() : submitStage(1)">
            {{ stagelevel === 2 ? 'Save' : 'Continue' }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiCamera } from "@mdi/js";

import { Drequest } from "@/plugins/Drequest";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, minLength: minLength(3) },
    about: { required, minLength: minLength(3) },
  },
  data() {
    return {
      stagelevel: 1,
      loader: {
        submit: false,
        isCity: false
      },
      name: "",
      about: "",
      post_type: "Article",
      blog_image: '',
      mblogimagefiles: null,
    };
  },
  components: {
    VueEditor
  },

  computed: {
    nameErrors() {
      const errors = { isvalid: true, value: [] };
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.value.push("Name must be at most 10 characters long") &&
        (errors.isvalid = false);
      !this.$v.name.required && errors.value.push("Name is required.") && (errors.isvalid = false);
      return errors;
    },

    aboutErrors() {
      const errors = { isvalid: true, value: [] };
      if (!this.$v.about.$dirty) return errors;
      !this.$v.about.minLength &&
        errors.value.push("Please describe your company") &&
        (errors.isvalid = false);
      !this.$v.about.required &&
        errors.value.push("Description is required.") &&
        (errors.isvalid = false);
      return errors;
    },
  },
  methods: {
    onImageChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      var blog_image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.blog_image = e.target.result;
      };
      reader.readAsDataURL(file);

      this.mblogimagefiles = file;

    },

    backToList() {
      this.$router.push({ name: "user.blog" });
    },
    checkValidationForm(step) {
        // if (this.nameErrors.isvalid) {
        //   return true;
        // }
        return false;
    },
    toNextStep() {
      this.stagelevel = this.stagelevel + 1;
    },
    goToPreviousStep() {
      this.stagelevel = this.stagelevel - 1;
    },
    resetStepStage(stage) {
      if (stage === 1) {
        this.name = "";
        this.about = "";
      }
      if (stage === 2) {
        this.name = "";
        this.about = "";
      }
    },

    submitStage(stage) {
      if (stage === 1) {
        this.$v.$touch();
        if (this.nameErrors.isvalid) {
          this.toNextStep();
        }
      }

      if (stage === 2) {
        this.$v.$touch();
        if (this.nameErrors.isvalid) {
          this.toNextStep();
        }
      }
    },
    submitCreateNewCompany() {
      this.$v.$touch();
      var bodyFormData = {
        enterprise: {
          name: this.name,
          about: this.about,
          "user.id": this.$store.getters.getUser.id,
        }
      };

      var formdata = new FormData();
      if (this.checkValidationForm()) {
        this.loader.submit = true;
        const detail = "";
        Drequest.api("enterprise.create")
          .data(bodyFormData)
          .raw(response => {
            if (response.success === true) {
              this.loader.submit = false;
              this.$store.commit("setCompany", {
                id: response.enterprise.id,
                about: response.enterprise.about,
                logo: response.enterprise.logo,
                name: response.enterprise.name,
                type: response.enterprise.enterprise_type
              });

              var formdatas = new FormData();
              formdatas.append("enterprise_form[logo]", this.mblogimagefiles);

              Drequest.api(`enterprise.uploadlogo?enterprise_id=${this.$store.getters.getCompany.id}`)
                .data(formdatas)
                .post((response) => {
                }).catch((err) => {
                });
              this.$fire({
                type: "success",
                text: "New enterprise created succefully.",
                timer: 2000
              });
              this.$router.push({ name: "user.list.company" });
            } else {
              this.loader.submit = false;
              this.$fire({
                type: "error",
                text: "Error during the creation of your company",
                timer: 2000
              });
            }
          })
          .catch(err => {
            console.log(err);
            this.$fire({
              type: "error",
              text: "Error during the creation of your company",
              timer: 2000
            });
            this.loader.submit = false;
          });
      }
    }
  },
  setup() {
    const icons = { mdiCamera };
    const post_list_type = [
      "Article",
      "Video",
      "Blog"
    ];
    return {
      icons,
      post_list_type
    };
  }};
</script>

<style lang="scss" scoped>
//@import 'vue-phone-number-input/dist/vue-phone-number-input.css';
@import "@/styles/styles.scss";
@import "@/styles/form-style.scss";

</style>